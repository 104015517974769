import React from 'react'
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'
import FourUp from '../../Layouts/FourUp'
import LogoCard from '../../General/Cards/LogoCard'

const Investors = ({ seo, bg, mantelData, logos }) => (
  <PageWrap bg={bg} seo={seo}>
    <Mantel {...mantelData} />
    <div className="max-w-4xl mx-auto pb-6 lg:px-6">
      {logos && (
        <FourUp noGaps>
          {logos.map((logo, i) => (
            <LogoCard key={`logo-item-${i}`} logo={logo} />
          ))}
        </FourUp>
      )}
    </div>
  </PageWrap>
)

export default Investors
