import React from 'react'
import styled from 'styled-components'

const container = `
  border-box
  bg-white
  p-2
  lg:p-4
  m-2
  shadow-blur
`

const Logo = styled.div`
  padding-bottom: 71.4%;
  background-image: url(${ props => props.logo });
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`

const LogoCard = ({ logo }) => (
  <div className={ container }>
    <Logo logo={ logo }/>
  </div>
)

export default LogoCard
