import React from 'react'
import styled from 'styled-components'
import { screen } from '../../../utils/index'

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;

  ${ screen('sm') } {
    grid-template-columns: repeat(2, 1fr);
  }

  ${ screen('lg') } {
    grid-template-columns: repeat(4, 1fr);
  }
`

const FourUp = ({ children, noGaps }) => (
  <GridWrap style={ noGaps ? { gridGap: '0px' } : {} }>
    { children.map((child, i) => (
      <div key={ `four-up-item-${ i }`}>{ child }</div>
    )) }
  </GridWrap>
)

export default FourUp
