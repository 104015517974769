import React from 'react'
import { graphql } from 'gatsby'

import Investors from '../../../components/Templates/Investors'
import NoTranslation from '../../../components/Templates/NoTranslation'

import { extract } from '../../../utils/graphql'
import { localizePath } from '../../../../config/i18n'
import { withPreview } from 'gatsby-source-prismic'

const Partners = ({ data, pageContext }) => {
  const { prismicOurPartners } = data
  if (!prismicOurPartners) return <NoTranslation></NoTranslation>

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(prismicOurPartners),
    mantelData: extract.mantel(prismicOurPartners, {
      parent: {
        path: localizePath(pageContext.locale, '/who-we-are'),
        text: 'Who we are',
      },
    }),
    bg: 1,
    logos: prismicOurPartners.data.logos.map((partner) => {
      return partner.partner_logo.url
    }),
  }
  return <Investors {...hydratedData} />
}

export default withPreview(Partners)

export const pageQuery = graphql`
  query PrismicOurPartnerBySlug($locale: String!) {
    prismicOurPartners(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        logos {
          partner_logo {
            url
            alt
          }
        }
      }
    }
  }
`
